<template>
    <div class="Product">
        <div class="columns is-multiline is-centered">
            <div v-for="(application, key) of applications" :key="key" 
            class="application column is-4">
                <div class="is-flex is-justify-content-center">
                    <img :src="application.icon" width="100">
                </div>
                <div>
                    <div style="height: 10px;"></div>
                    <div class="has-text-centered">
                        {{application.title}}
                    </div>
                    <div class="has-text-centered">
                        <a :href="application.download" target="_blank">
                            Download
                        </a>
                        <span v-if="application.privacyPolicy"> | </span>
                        <router-link v-if="application.privacyPolicy" :to="application.privacyPolicy">
                            Privacy Policy
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Product',
    data() {
        return {
            applications: [
                {
                    title: 'ข่าวหุ้น อัพเดทหุ้นไทยรายนาที',
                    icon: '/images/khaohoon.png',
                    download: 'https://play.google.com/store/apps/details?id=com.boombeam.khaohoon',
                    privacyPolicy: '/privacy-policy/khaohoon',
                },
                {
                    title: 'รวมลิ้งค์คอร์ดเพลง',
                    icon: '/images/chordpleng.png',
                    download: 'https://play.google.com/store/apps/details?id=com.boombeam.chordpleng',
                    privacyPolicy: '/privacy-policy/chordpleng',
                },
                {
                    title: 'เช็คเลขพัสดุ ขนส่งยอดนิยม',
                    icon: '/images/posttrack.png',
                    download: 'https://play.google.com/store/apps/details?id=com.boombeam.posttrack',
                    privacyPolicy: '/privacy-policy/posttrack',
                },
                {
                    title: 'เช็คเลข kerry เคอรี่',
                    icon: '/images/kerry.png',
                    download: 'https://play.google.com/store/apps/details?id=com.mana.kerry',
                    privacyPolicy: '/privacy-policy/kerry',
                },
                {
                    title: 'ราคาทองวันนี้',
                    icon: '/images/rakhathong.png',
                    download: 'https://play.google.com/store/apps/details?id=com.boombeam.rakathong',
                    privacyPolicy: '/privacy-policy/rakathong',
                },
                {
                    title: 'สแกนหุ้น หุ้นอินเทรนด์ Scan Stock Intrend',
                    icon: '/images/stockintrend.png',
                    download: 'https://play.google.com/store/apps/details?id=com.boombeam.stockintrend',
                    privacyPolicy: '/privacy-policy/stockintrend',
                },
                {
                    title: 'เสียงแมงจีซอน ไว้ล่อแมง ไฟล์เล็ก',
                    icon: '/images/sianggeesorn.png',
                    download: 'https://play.google.com/store/apps/details?id=com.boombeam.sainggeesorn',
                    privacyPolicy: '/privacy-policy/sianggeesorn',
                },
                {
                    title: 'เสียงนกต่อ เสียงไก่ต่อ',
                    icon: '/images/siangnok.jpeg',
                    download: 'https://play.google.com/store/apps/details?id=com.boombeam.siangnok',
                    privacyPolicy: '/privacy-policy/siangnok',
                },
                {
                    title: 'ข้อสอบใบขับขี่',
                    icon: '/images/drivelicenseexamination.png',
                    download: 'https://play.google.com/store/apps/details?id=com.boombeam.drivelicenseexamination',
                    privacyPolicy: '/privacy-policy/drivelicenseexamination',
                },
                {
                    title: 'เช็คเลขพัสดุ Best Express',
                    icon: '/images/best_express.png',
                    download: 'https://play.google.com/store/apps/details?id=com.boombeam.best_express',
                    privacyPolicy: '/privacy-policy/bestexpress',
                },
                {
                    title: 'ตารางแคลอรี่',
                    icon: '/images/calories.png',
                    download: 'https://play.google.com/store/apps/details?id=com.boombeam.calories',
                    privacyPolicy: '/privacy-policy/calories',
                },
                // {
                //     title: 'กขค ABC 123 มีเสียง',
                //     icon: '/images/korkhorkor.png',
                //     download: 'https://play.google.com/store/apps/details?id=com.boombeam.korkhorkor',
                //     privacyPolicy: '/privacy-policy/korkhorkor',
                // },
            ],
        }
    },
}
</script>
<style scoped>
.application img {
    border: solid 1px gray;
    border-radius: 8px;
    min-height: 100px;
}
</style>